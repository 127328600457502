/* eslint-disable @typescript-eslint/no-explicit-any */
export const callApi = async (url: string, options?: any): Promise<Response> => {
    const res = await fetch(url, options);
    if (res.ok) {
        return res;
    }
    else if (res.status == 401) {
        localStorage.removeItem('JWT');
        window.location.href="/login";
    //  navigate('/login');
        throw Error('Unauthorized');
    } else if (res.status == 404) {
        throw Error('Not found');
    }
    else {
        throw Error('Unknown');
    }
}

export const callApiJson = async (url: string, options?: any): Promise<any> => {
    const res = await callApi(url, options);
    return res.json();
}

export const callApiBlob = async (url: string, options?: any): Promise<Blob> => {
    const res = await callApi(url, options);
    return res.blob();
}

export default callApiJson;
