import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './CustomModal.css';

export const SyncModal = ({show, onSyncAction, onHide, title, description}) => {

    return (
        <Modal
            show={show}
            size='lg'
            onHide={onHide}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <h5 className='modal-description'>{description}</h5>
            <Modal.Footer style={{ marginTop: '10px' }}>
                <Button variant="primary" onClick={() => onSyncAction('salesforce')}>Sync Salesforce Project</Button>
                <Button variant="primary" onClick={() => onSyncAction('scoop')}>Sync Scoop Project</Button>
            </Modal.Footer>
        </Modal>
    );
}

SyncModal.propTypes = {
    show: PropTypes.bool,
    onSyncAction: PropTypes.func,
    onHide: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string
}