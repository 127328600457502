/* eslint-disable @typescript-eslint/no-explicit-any */
import 'react-datepicker/dist/react-datepicker.css';
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@mui/material';
import LoadingModal from '../../components/CustomModal/LoadingModal';
import '../../components/Footer/Footer';
import '../../components/NavBar/NavBar';
import DatePicker from 'react-datepicker';
import Typography from '@mui/material/Typography';
import { useProjects } from '../../api/useProjects';
import { useDatatapeExport } from '../../api/useDatatapeExport';
import { queryClient} from '../../index';
import {SyncModal} from '../../components/CustomModal/SyncModal';
import {
  DataGrid,
  GridRowId,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
} from '@mui/x-data-grid';

import { getColumnDefs } from './ColumnDefinitions';
import callApiJson from '../../api/callApi';

const initialTrancheSelections = [
  {
    trancheType: 'contract',
    ready: false,
    submitSaleOne: false,
    approvalSaleOne: false
  },
  {
    trancheType: 'ntp',
    ready: false,
    submitSaleOne: false,
    approvalSaleOne: false
  },
  {
    trancheType: 'procure',
    ready: false,
    submitSaleOne: false,
    approvalSaleOne: false
  },
  {
    trancheType: 'commence',
    ready: false,
    submitSaleOne: false,
    approvalSaleOne: false
  },
  {
    trancheType: 'midpoint',
    ready: false,
    submitSaleOne: false,
    approvalSaleOne: false
  },
  {
    trancheType: 'mc',
    ready: false,
    submitSaleOne: false,
    approvalSaleOne: false,
    submitSaleTwo: false,
    approvalSaleTwo: false
  },
  {
    trancheType: 'sc',
    ready: false,
    submitSaleOne: false,
    approvalSaleOne: false,
    submitSaleTwo: false,
    approvalSaleTwo: false
  },
  {
    trancheType: 'fc',
    ready: false,
    submitSaleOne: false,
    approvalSaleOne: false
  },
  {
    trancheType: 'cod',
    ready: false,
    submitSaleOne: false,
    approvalSaleOne: false,
    submitSaleTwo: false,
    approvalSaleTwo: false
  }
];

function Projects() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedFunds, setSelectedFunds] = useState<string[]>([]);
  const [removedProjects, setRemovedProjects] = useState<any>([])
  const [filteredProjects, setFilteredProjects] = useState<GridRowId[]>()
  const [filename, setFilename] = useState('DataTape');
  const [syncModalShow, setSyncModalShow] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [trancheSelections, setTrancheSelections] = useState(initialTrancheSelections);
  const [projectIDFilter, setProjectIDFilter] = useState('');

  const getSearchURL = () => {
    const basePath = "/api/projects";
    const queryParams = {}

    if (selectedFunds) {
      queryParams['funds'] = selectedFunds.join(',')
    }

    if (projectIDFilter) {
      queryParams['project_id'] = projectIDFilter
    }

    const trancheDates: string[] = []
    for (const tranche of trancheSelections) {
      if (tranche.ready) {
        trancheDates.push(`${tranche.trancheType}_tranche_ready`)
      }

      if (tranche.approvalSaleOne) {
        trancheDates.push(`${tranche.trancheType}_tranche_approval_sale_one`)
      }

      if (tranche.submitSaleOne) {
        trancheDates.push(`${tranche.trancheType}_tranche_submit_sale_one`)
      }

      if ('approvalSaleTwo' in tranche &&  tranche.approvalSaleTwo) {
        trancheDates.push(`${tranche.trancheType}_tranche_approval_sale_two`)
      }

      if ('submitSaleTwo' in tranche &&  tranche.submitSaleTwo) {
        trancheDates.push(`${tranche.trancheType}_tranche_submit_sale_two`)
      }
    }

    if (trancheDates.length > 0) {
      queryParams['start_date'] = startDate.toISOString()
      queryParams['end_date'] = endDate.toISOString()
      queryParams['tranche_dates'] = trancheDates.join(',')
    }
    return `${basePath}?${new URLSearchParams(queryParams).toString()}`;
  }
  const {
    data: projectsData,
    isLoading, isError,
    refetch: refetchProjects
  } = useProjects(getSearchURL());
  const exportDatatape = useDatatapeExport();

  if (isError) {
    toast.error('Failed to fetch projects', {
      position: toast.POSITION.TOP_RIGHT
    });
  }
  const handleFundChange = (e: any) => {
      const funds: string[] = [];
      e.map(x => {
        funds.push(x.value);
      })

      setSelectedFunds(funds);
  };

  const handleFilenameChange = (e) => {
    setFilename(e.target.value);
  }

  const handleRemoval= (id)  => {
    const newRemovedProjects = [...removedProjects, id]
    setRemovedProjects(newRemovedProjects)

    queryClient.setQueryData(['projects'], (oldData: any) => {
      return {projects: oldData.projects?.filter(project => {
        return !newRemovedProjects.includes(project.project_id)
      }), amountCOs: oldData.amountCOs }
    });

  }

  const handleProjectSync = (projectId) => {
    setSelectedProjectId(projectId);
    setSyncModalShow(true);
  }

  const apiRef = useGridApiRef();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const funds = [
    'IR-SR Holdings (Ironrock) FY 2023',
    'Nelnet FY 2024'
  ].map(x => { return { 'value': x, 'label': x } })

  const runReport = () => {
    refetchProjects()
  }

  const handleTrancheChange = (index, field) => {
    const updatedSelections = [...trancheSelections];
    updatedSelections[index] = {
      ...updatedSelections[index],
      [field]: !updatedSelections[index][field],
    };
    setTrancheSelections(updatedSelections);
  };

  const updateAll = (value) => {
    const updatedSelections = [...trancheSelections];
    updatedSelections.forEach(tranche => {
      tranche.ready = value;
      tranche.submitSaleOne = value;
      tranche.approvalSaleOne = value;
      if ('submitSaleTwo' in tranche) {
        tranche.submitSaleTwo = value;
      }
      if ('approvalSaleTwo' in tranche) {
        tranche.approvalSaleTwo = value;
      }
    });
    setTrancheSelections(updatedSelections);
  }

  function renderLoadingModal() {
    return (
      <>
        <LoadingModal
          show={isLoading}
        />
      </>
    );
  }

  const onResync = async (type: string) => {
    setSyncModalShow(false);
    try {
      toast.info('Starting operation...', {
        position: toast.POSITION.TOP_RIGHT
      });
      await callApiJson(`/api/resync-project?id=${selectedProjectId}&type=${type}`);
      toast.success('Operation started successfully', {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (error) {
      toast.error(`${error}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const renderQuoteSyncModal = () => {
    return <SyncModal
      show={syncModalShow}
      onHide={() => setSyncModalShow(false)}
      onSyncAction={onResync}
      title="Re-sync project data"
      description="This action will re-sync data for this project."
    />
  }


  if (!projectsData) {
    return <></>
  }

  const handleExport = async () => {
    const filteredSortedIds = gridFilteredSortedRowIdsSelector(apiRef);

    await exportDatatape.mutateAsync({
      filteredSortedIds: filteredSortedIds,
      filename: filename
    });
  };

  const handleStateChange = () => {
    const postFiltered = gridFilteredSortedRowIdsSelector(apiRef)
    const haveSameElements = (array1, array2) => {
      return array1.length === array2.length &&
             array1.sort().every((value, index) => value === array2.sort()[index]);
    };

    if (filteredProjects == null || !haveSameElements(postFiltered, filteredProjects)) {
      setFilteredProjects(postFiltered)
    }
  }

  const formattedMoney = (number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  }


  const formattedNumber = (number: number, decimal_places?: number) => {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: decimal_places || 2
    }).format(number);
  }

  const getSummary = () => {
    if(!projectsData) return <></>;

    let summaryProjects = projectsData.projects;

    if (filteredProjects != null) {
      summaryProjects = summaryProjects.filter(project => {
        return filteredProjects.includes(project.project_id)
      })
    }

      const totals = summaryProjects.reduce((acc, project) => {

      const turnkeyNom = parseFloat(project.turnkey_nom) || 0;
      const pvSizeDc = parseFloat(project.pv_size_dc_kw_sd) || 0;
      const pv_size_dc_kw_sd = parseFloat(project.pv_size_dc_kw_sd) || 0;
      const epc_total_nom_sum = parseFloat(project.epc_total_nom_sum) || 0;
      const itc_nom = parseFloat(project.itc_nom) || 0;

      // Calculated in Scoop
      const contract_tranche_nom_sale_one = parseFloat(project.contract_tranche_nom_sale_one) || 0;
      const ntp_tranche_nom_sale_one = parseFloat(project.ntp_tranche_nom_sale_one) || 0;
      const procure_tranche_nom_sale_one = parseFloat(project.procure_tranche_nom_sale_one) || 0;
      const commence_tranche_nom_sale_one = parseFloat(project.commence_tranche_nom_sale_one) || 0;
      const midpoint_tranche_nom_sale_one = parseFloat(project.midpoint_tranche_nom_sale_one) || 0;
      const mc_tranche_nom_sale_one = parseFloat(project.mc_tranche_nom_sale_one) || 0;
      const sc_tranche_nom_sale_one = parseFloat(project.sc_tranche_nom_sale_one) || 0;
      const fc_tranche_nom_sale_one = parseFloat(project.fc_tranche_nom_sale_one) || 0;
      const cod_tranche_nom_sale_one = parseFloat(project.cod_tranche_nom_sale_one) || 0;

      // Accumulate the sums
      acc.totalTurnkeyNominal += turnkeyNom;
      acc.totalPvSizeDc += pvSizeDc;
      acc.pv_size_dc_kw_sd += pv_size_dc_kw_sd;
      acc.epc_total_nom_sum += epc_total_nom_sum;
      acc.itc_nom += itc_nom;
      acc.contract_tranche_nom_sale_one += contract_tranche_nom_sale_one;
      acc.ntp_tranche_nom_sale_one += ntp_tranche_nom_sale_one;
      acc.procure_tranche_nom_sale_one += procure_tranche_nom_sale_one;
      acc.commence_tranche_nom_sale_one += commence_tranche_nom_sale_one;
      acc.midpoint_tranche_nom_sale_one += midpoint_tranche_nom_sale_one;
      acc.mc_tranche_nom_sale_one += mc_tranche_nom_sale_one;
      acc.sc_tranche_nom_sale_one += sc_tranche_nom_sale_one;
      acc.fc_tranche_nom_sale_one += fc_tranche_nom_sale_one;
      acc.cod_tranche_nom_sale_one += cod_tranche_nom_sale_one;
      return acc;
  }, {
      totalTurnkeyNominal: 0,
      totalPvSizeDc: 0,
      pv_size_dc_kw_sd: 0,
      epc_total_nom_sum: 0,
      itc_nom: 0,

      contract_tranche_nom_sale_one: 0,
      ntp_tranche_nom_sale_one: 0,
      procure_tranche_nom_sale_one: 0,
      commence_tranche_nom_sale_one: 0,
      midpoint_tranche_nom_sale_one: 0,
      mc_tranche_nom_sale_one: 0,
      sc_tranche_nom_sale_one: 0,
      fc_tranche_nom_sale_one: 0,
      cod_tranche_nom_sale_one: 0,
    });

    return (
        <Container className="mt-3">
            <Row className="justify-content-md-center">
              <h3>Totals</h3>
              <Col md={3}>
                  <div>
                      <Typography variant="body1">PV Size DC: {formattedNumber(totals.totalPvSizeDc)}</Typography>
                      <Typography variant="body1">Turnkey Nominal: {formattedMoney(totals.totalTurnkeyNominal)}</Typography>
                      <Typography variant="body1">PV Size DC KW SD: {formattedNumber(totals.pv_size_dc_kw_sd)}</Typography>
                      <Typography variant="body1">EPC Nom Sum: {formattedMoney(totals.epc_total_nom_sum)}</Typography>
                      <Typography variant="body1">ITC Nom: {formattedMoney(totals.itc_nom)}</Typography>
                  </div>
              </Col>
              <Col md={3}>
                  <div>
                      <Typography variant="body1">Contract Nom Sale One: {formattedMoney(totals.contract_tranche_nom_sale_one)}</Typography>
                      <Typography variant="body1">NTP Nom Sale One: {formattedMoney(totals.ntp_tranche_nom_sale_one)}</Typography>
                      <Typography variant="body1">Procure Nom Sale One: {formattedMoney(totals.procure_tranche_nom_sale_one)}</Typography>
                      <Typography variant="body1">Commence Nom Sale One: {formattedMoney(totals.commence_tranche_nom_sale_one)}</Typography>
                      <Typography variant="body1">Midpoint Nom Sale One: {formattedMoney(totals.midpoint_tranche_nom_sale_one)}</Typography>
                  </div>
              </Col>
              <Col md={3}>
                  <div>
                      <Typography variant="body1">MC Nom Sale One: {formattedMoney(totals.mc_tranche_nom_sale_one)}</Typography>
                      <Typography variant="body1">SC Nom Sale One: {formattedMoney(totals.sc_tranche_nom_sale_one)}</Typography>
                      <Typography variant="body1">FC Nom Sale One: {formattedMoney(totals.fc_tranche_nom_sale_one)}</Typography>
                      <Typography variant="body1">COD Nom Sale One: {formattedMoney(totals.cod_tranche_nom_sale_one)}</Typography>
                  </div>
              </Col>
              <Col
                md={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",

                }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '7px'
                    }}
                    >
                    <input type="text" value={filename} onChange={handleFilenameChange} placeholder="DataTape" />
                    <Button style={{ color: 'white' }} variant="contained" disabled={exportDatatape.isPending} onClick={handleExport}>Export</Button>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '7px'
                    }}
                    >
                    <Button style={{ color: 'white' }} onClick={runReport} variant="contained" >Submit</Button>
                  </div>
              </Col>
            </Row>
        </Container>
    );
  };

  const columnDefinitions = getColumnDefs(handleRemoval, projectsData?.amountCOs, handleProjectSync);

  return (
    <div style={{ overflow: 'auto ' }}>
      <Container>
        {renderLoadingModal()}
        {renderQuoteSyncModal()}
        <h2 className='page-heading'>Projects</h2>
        <Row className='number-section'>
          <Col sm={3}>
            {
              <div className='view-all-btn'>
                <Select
                  options={funds}
                  closeMenuOnSelect={false}
                  isMulti
                  onChange={handleFundChange}
                  placeholder="Filter by Fund"
                />
              </div>
            }
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            {
              <div className='view-all-btn mb-2'>
                 <input type="text" className='border p-1' placeholder="Filter by Project ID " value={projectIDFilter} onChange={(e) => setProjectIDFilter(e.target.value)} />
              </div>
            }
          </Col>
        </Row>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Tranche Milestone Date Filters</Accordion.Header>

            <Accordion.Body>
              <div style={{display: 'flex', justifyContent: 'center', gap: '8px'}}>
                <div>Start Date <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /></div>
                <div>End Date <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} /></div>
              </div>
              <div style={{width: '15%', display: 'flex', justifyContent:'space-between'}}>
                <a href="#" onClick={() => updateAll(true)}>Select All</a>
                <a href="#" onClick={() => updateAll(false)}>Deslect All</a>
              </div>
              <Table>
                <thead>
                  <tr>
                    <th>Tranche Milestones</th>
                    <th>Ready</th>
                    <th>Submit Sale One</th>
                    <th>Approve Sale One</th>
                    <th>Submit Sale Two</th>
                    <th>Approve Sale Two</th>
                  </tr>
                </thead>
                <tbody>
                {trancheSelections.map((tranche, index) => (
                  <tr key={index}>
                    <td>{tranche.trancheType}</td>
                    <td><input type="checkbox" checked={tranche.ready} onChange={() => handleTrancheChange(index, 'ready')} /></td>
                    <td><input type="checkbox" checked={tranche.submitSaleOne} onChange={() => handleTrancheChange(index, 'submitSaleOne')}  /></td>
                    <td><input type="checkbox" checked={tranche.approvalSaleOne} onChange={() => handleTrancheChange(index, 'approvalSaleOne')}  /></td>
                    {
                      ('submitSaleTwo' in tranche) &&
                      <td><input type="checkbox" checked={tranche.submitSaleTwo} onChange={() => handleTrancheChange(index, 'submitSaleTwo')}  /></td>
                    }
                    {
                      ('approvalSaleTwo' in tranche) &&
                      <td><input type="checkbox" checked={tranche.approvalSaleTwo}  onChange={() => handleTrancheChange(index, 'approvalSaleTwo')} /></td>
                    }
                  </tr>
                ))}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {getSummary()}
        <div>
          <DataGrid
            onStateChange={() => handleStateChange()}
            apiRef={apiRef}
            rows={projectsData.projects}
            getRowId={(row) => row.project_id.toString()}
            columns={columnDefinitions}
            style={{ backgroundColor: 'white', marginTop: '20px', height: 900, width: '100%'  }}
          />
        </div>
      </Container>
    </div>
  );
}

export default Projects;